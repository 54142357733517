.navbar-nav {
  font-weight: 600;
}

.add-shadow {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  font-weight: 700;
}

.collection-description {
  font-size: 24px;
  font-weight: 600;
  margin-top: 22px;
  text-align: justify;
  color: #6a6a6a;
}

.mint-btn {
  width: 100%;
  background-color: #E79635 !important;
  border-color: #E79635 !important;
}
.mint-btn:hover {
  background-color: #cf8831 !important;
  border-color: #cf8831 !important;
}

.opensea-btn {
  width: 100%;
  background-color: #6C99B9 !important;
  border-color: #6C99B9 !important;
}
.opensea-btn:hover {
  background-color: #6086a1 !important;
  border-color: #6086a1 !important;
}

.instagram-btn {
  width: 100%;
  background-color: #CF6874 !important;
  border-color: #CF6874 !important;
}
.instagram-btn:hover {
  background-color: #b15761 !important;
  border-color: #b15761 !important;
}

.faq-btn {
  width: 100%;
}

.carousel-btn {
  font-size: 1.4em !important;
  background-color: transparent !important;
  border: 1px solid black !important;
  color: #E79635 !important;
  font-weight: 600 !important;
}
.carousel-btn:hover {
  background-color: #E79635 !important;
  color: #000 !important;
  border-color: #E79635 !important;
}
.carousel-left-btn {
  margin-right: 4px !important;
}


/* TIMELINE */
.circle {
  padding: 13px 20px;
  border-radius: 50%;
  background-color: #E79635;
  color: #fff;
  max-height: 50px;
  z-index: 2;
}

.how-it-works.row .col-2 {
  align-self: stretch;
}
/* .how-it-works.row .col-2::after {
  content: "";
  position: absolute;
  border-left: 3px solid #E79635;
  z-index: 1;
} */
.how-it-works.row .col-2.bottom::after {
  height: 50%;
  left: 50%;
  top: 50%;
}
.how-it-works.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}
.how-it-works.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}

.timeline div {
  padding: 0;
  height: 40px;
}
.timeline hr {
  border-top: 3px solid #000;
  margin: 0;
  top: 17px;
  position: relative;
}
.timeline .col-2 {
  display: flex;
  overflow: hidden;
}
.timeline .corner {
  border: 3px solid #E79635;
  width: 100%;
  position: relative;
  border-radius: 15px;
}
.timeline .top-right {
  left: 50%;
  top: -50%;
}
.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}
.timeline .top-left {
  left: -50%;
  top: -50%;
}
.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

/* END TIMELINE */


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1.5rem !important;
  }
}